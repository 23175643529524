export const validateEmail = (mail: string) => {
    const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!mail.match(mailFormat);
};

export const validatePassword = (password: string) => {
    const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
    return !!password.match(passwordFormat);
};

export const validateTextInput = (text: string) => {
    const textFormat = /^[^'<>"#*${}[\]()]+$/;
    return !!text.match(textFormat);
};