import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import * as api from '../services/api';
import constants from '../ressources/constants';
import { AuthResult, JWT, User } from '../ressources/types/auth.types';
import {
    Configuration,
    Floorball,
    Order,
    OrderResponse, Status,
    StocksResponse,
    StockType,
} from '../ressources/types/floorball.types';

export function authenticate(email: string, password: string, service: any = api) {
    return (dispatch: any) => {
        const authPromise = service.login(email, password);
        return handleAuthentication(authPromise, dispatch);
    };
}

export function authenticateToken(token: string, service: any = api) {
    return (dispatch: any) => {
        const authPromise = service.loginAsGuest(token);
        return handleAuthentication(authPromise, dispatch);
    };
}

export function changePassword(currentPassword: string, newPassword: string, newPasswordRepeated: string, service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'CHANGE_PASSWORD_STARTED' });
        return service.changePassword(currentPassword, newPassword, newPasswordRepeated).then(() => {
            dispatch({ type: 'CHANGE_PASSWORD_SUCCEEDED' });
        }).catch((error: any) => {
            dispatch({ type: 'CHANGE_PASSWORD_FAILED' });
            throw error;
        })
    };
}

function handleAuthentication(authPromise: Promise<AxiosResponse<AuthResult>>, dispatch: any) {
    return authPromise.then((result: AxiosResponse<AuthResult>) => {
        if (result.data.accessToken != null && localStorage != null) {
            localStorage.setItem('accessToken', result.data.accessToken);
        }
        if (result.data.refreshToken != null && localStorage != null) {
            localStorage.setItem('refreshToken', result.data.refreshToken);
        }
        dispatch({ type: 'AUTHENTICATION_SUCCEEDED', user: result.data.user });
        dispatch(getAccountDetails());
    }).catch((error: Error) => {
        dispatch({ type: 'AUTHENTICATION_FAILED', error });
    });
}

export function signout(service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'SIGNOUT' });
        service.signout().then(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        });
    };
}

export function getAccountDetails() {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_ACCOUNT_DETAILS_STARTED' });
        const jwt = localStorage.getItem(constants.ACCES_STOKEN);
        if (jwt) {
            const jwtUser: JWT = jwtDecode(jwt);
            let admin;
            let isGuest;
            if (Array.isArray(jwtUser.role)) {
                admin = jwtUser.role.includes('Admin');
                isGuest = jwtUser.role.includes('Guest');
            } else {
                admin = jwtUser.role === 'Admin';
                isGuest = jwtUser.role === 'Guest';
            }
            const user: User = {
                email: jwtUser.email,
                username: jwtUser.sub,
                firstname: jwtUser.name,
                lastname: jwtUser.family_name,
                admin,
                isGuest,
            };
            dispatch({ type: 'FETCH_ACCOUNT_DETAILS_SUCCEEDED', user });
        } else {
            dispatch({ type: 'FETCH_ACCOUNT_DETAILS_FAILED' });
        }
    };
}

export function setConfigurationLeftColor(material: StockType) {
    return (dispatch: any) => {
        if (material != null && material.materialNumber != null) {
            dispatch({ type: 'SET_LEFTCOLOR_SUCCEEDED', leftColor: material });
        } else {
            dispatch({ type: 'CONFIGURATION_FAILED', error: new Error('invalid color') });
        }
    };
}

export function setConfigurationRightColor(material: StockType) {
    return (dispatch: any) => {
        if (material != null && material.materialNumber != null) {
            dispatch({ type: 'SET_RIGHTCOLOR_SUCCEEDED', rightColor: material });
        } else {
            dispatch({ type: 'CONFIGURATION_FAILED', error: new Error('invalid color') });
        }
    };
}

export function resetConfiguration() {
    return (dispatch: any) => {
        dispatch({ type: 'RESET_CONFIGURATION' });
    };
}

export function fetchStocks(service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_STOCK_STARTED' });
        return service
            .getStocks()
            .then((result: AxiosResponse<StocksResponse>) => {
                dispatch({ type: 'FETCH_STOCK_SUCCEEDED', items: result.data.stocks });
            })
            .catch((error: Error) => {
                dispatch({ type: 'FETCH_STOCKS_FAILED', error });
            });
    };
}

export function createOrder(orderRequest: any, service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'CREATE_ORDER_STARTED' });
        return service
            .postOrder(orderRequest)
            .then((result: AxiosResponse<Order>) => {
                if (result.data != null && localStorage != null) {
                    const persistentOrderState = {
                        timestamp: new Date().getTime(),
                        persistentOrder: result.data,
                    };
                    localStorage.setItem('persistentOrderState', JSON.stringify(persistentOrderState));
                }
                dispatch({ type: 'CREATE_ORDER_SUCCEEDED', order: result.data });
            })
            .catch((error: Error) => {
                dispatch({ type: 'CREATE_ORDER_FAILED', error });
            });
    };
}

export function resetOrders() {
    return (dispatch: any) => {
        localStorage.removeItem('persistentOrderState');
        dispatch({ type: 'RESET_ACTIVE_ORDER' });
        dispatch({ type: 'RESET_ORDERS' });
    };
}

export function fetchOrders(service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_ORDERS_STARTED' });
        return service.getOrders().then((result: AxiosResponse<OrderResponse>) => {
            dispatch({ type: 'FETCH_ORDERS_SUCCEEDED', items: result.data.orders });
        }).catch((error: Error) => {
            dispatch({ type: 'FETCH_ORDERS_FAILED', error });
        });
    };
}

export function fetchOrder(id: string, service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_ORDERS_STARTED' });
        return service.getOrder(id).then((result: AxiosResponse<OrderResponse>) => {
            dispatch({ type: 'FETCH_ORDERS_SUCCEEDED', items: [ result.data ] });
        }).catch((error: Error) => {
            dispatch({ type: 'FETCH_ORDERS_FAILED', error });
        });
    };
}

export function fetchFloorballData(serialNumber: number, service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_FLOORBALLDATA_STARTED' });
        return service
            .getFloorballData(serialNumber)
            .then((result: AxiosResponse<Floorball>) => {
                dispatch({ type: 'FETCH_FLOORBALLDATA_SUCCEEDED', data: result.data });
            })
            .catch((error: Error) => {
                dispatch({ type: 'FETCH_FLOORBALLDATA_FAILED', error });
            });
    };
}

export function resetFloorballData() {
    return (dispatch: any) => {
        dispatch({ type: 'RESET_FLOORBALLDATA' });
    };
}

export function fetchAppConfig(service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'FETCH_APP_CONFIG_STARTED' });
        return service
            .getAppConfig()
            .then((result: AxiosResponse<Status>) => {
                dispatch({ type: 'FETCH_APP_CONFIG_SUCCEEDED', status: result.data });
            })
            .catch((error: Error) => {
                dispatch({ type: 'FETCH_APP_CONFIG_FAILED', error });
            });
    };
}

export function setAppConfig(config: Configuration, service: any = api) {
    return (dispatch: any) => {
        dispatch({ type: 'PUT_APP_CONFIG_STARTED' });
        return service
            .putAppConfig(config)
            .then((result: AxiosResponse<Configuration>) => {
                dispatch({ type: 'PUT_APP_CONFIG_SUCCEEDED', orderService: result.data });
            })
            .catch((error: Error) => {
                dispatch({ type: 'PUT_APP_CONFIG_FAILED', error });
            });
    };
}
