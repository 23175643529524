import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
    getActiveOrderIsLoading,
    getAppConfig,
    getAppConfigIsLoading,
    getConfigurationLeftColor,
    getConfigurationRightColor, getStocks, getUser,
} from '../../redux/reducers';
import './orderNavigator.scss';
import { createOrder, fetchAppConfig } from '../../redux/actions';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import {StockType} from "../../ressources/types/floorball.types";

const BASE_MIN_ORDER_AMOUNT = 1;
const BASE_MAX_ORDER_AMOUNT = 20;

function OrderNavigator({
                            user,
                            leftColor,
                            rightColor,
                            isLoadingOrder,
                            isLoadingAppConfig,
                            appConfig,
                            createOrder,
                            stocks
                        }: PropsFromRedux) {
    const { t } = useTranslation();
    const [isOrderDisabledInConfig, setIsOrderDisabledInConfig] = useState<boolean>(true);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
    const [orderAmount, setOrderAmount] = useState<number>(BASE_MIN_ORDER_AMOUNT);
    const [orderMaxAmount, setOrderMaxAmount] = useState<number>(BASE_MAX_ORDER_AMOUNT);

    useEffect(() => {
        if (stocks == null) return
        
        let scopedMaxAmount = BASE_MAX_ORDER_AMOUNT;
        stocks.forEach((stock: StockType) => {
            const stockOrderableAmount = stock.amount - stock.minimumNeededInStock
            if ((stock.color?.name === leftColor.color?.name || stock.color?.name === rightColor.color?.name) && stockOrderableAmount < scopedMaxAmount) {
                scopedMaxAmount = stockOrderableAmount;
            }
        });
        setOrderMaxAmount(scopedMaxAmount) // Ensure that the user cannot order more floorballs than are available.
    }, [stocks, leftColor, rightColor]);
    
    const handleSubmit = () => {
        setConfirmationOpen(true);
    };

    function confirmationAction() {
        const orderRequest = {
            amount: orderAmount,
            floorball: { leftHalf : leftColor, rightHalf : rightColor }
        }
        createOrder(orderRequest);
    }

    useEffect(() => {
        if (appConfig) {
            setIsOrderDisabledInConfig(!appConfig.isSystemAvailable);
        }
    }, [appConfig, isLoadingAppConfig, isOrderDisabledInConfig]);

    function isLoggedIn() {
        return user != null;
    }

    function hasChosenColors() {
        return leftColor.color != null && rightColor.color != null;
    }

    function isButtonDisabled() {
        return !isLoggedIn() || !hasChosenColors() || isLoadingOrder || Number.isNaN(orderAmount) || orderAmount < BASE_MIN_ORDER_AMOUNT || orderAmount > orderMaxAmount;
    }

    function getButtonLabel() {
        if (!isLoggedIn()) {
            return t('pages.configurator.orderButton.pleaseLogIn');
        }
        if (!hasChosenColors()) {
            return t('pages.configurator.orderButton.selectColors');
        }
        if (Number.isNaN(orderAmount) || orderAmount < BASE_MIN_ORDER_AMOUNT || orderAmount > orderMaxAmount) {
            return t('pages.configurator.orderButton.chooseValidAmount');
        }
        return t('pages.configurator.orderButton.orderFloorball');
    }

    return (
        <div id='order-navigator'>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                action={() => confirmationAction()}
                title={t('orderNavigatorBtn-confirmationModal-title')}
                description={t('orderNavigatorBtn-confirmationModal-description')}
            />
            {user?.isGuest === false && <div className='order-amount-picker'>
                <Button disabled={isLoadingOrder || isOrderDisabledInConfig || orderAmount <= BASE_MIN_ORDER_AMOUNT} 
                        onClick={() => setOrderAmount(orderAmount - 1)}>-</Button>
                <input min={BASE_MIN_ORDER_AMOUNT} 
                       max={orderMaxAmount} 
                       type='number' 
                       value={orderAmount}
                       onChange={(event) => setOrderAmount(Number.parseInt(event.target.value ?? 1, 10))}
                       disabled={isLoadingOrder || isOrderDisabledInConfig}/>
                <Button disabled={isLoadingOrder || isOrderDisabledInConfig || orderAmount >= orderMaxAmount}
                        onClick={() => setOrderAmount(orderAmount + 1)}>+</Button>
            </div>}
            
            <Button loading={isLoadingOrder || isOrderDisabledInConfig} disabled={isButtonDisabled()}
                    onClick={handleSubmit}>
                {getButtonLabel()}
            </Button>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    isLoadingOrder: getActiveOrderIsLoading(state),
    isLoadingAppConfig: getAppConfigIsLoading(state),
    appConfig: getAppConfig(state),
    user: getUser(state),
    stocks: getStocks(state),
});

const mapDispatchToProps = { createOrder, fetchAppConfig };

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrderNavigator);
