/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers';
import 'semantic-ui-css/semantic.min.css';
import './ressources/styles/index.scss';
import './utils/translator.ts';
import App from './App';
import reportWebVitals from './reportWebVitals';

const ORDER_STATE_EXPIRATION = 24 * 60 * 60 * 1000;

/**
 * Builds a persistent state for the {@link createStore Redux store} using data from the {@link localStorage localStorage}.
 * As of now, this persistent state only includes the active order of a guest user, which is intended to be the only
 * order of the user for the duration of the "Open-door day". For this reason, the persistent state in the
 * {@link localStorage localStorage} will be cleared after one day.
 *
 * @author Lukas Messmer
 * @since 31.03.2022
 */
function buildPersistentState(): any {
    const persistentOrderState = localStorage.getItem('persistentOrderState');
    if (persistentOrderState != null) {
        const orderState = JSON.parse(persistentOrderState as string);
        const now = new Date().getTime();
        if (now - orderState.timestamp > ORDER_STATE_EXPIRATION) {
            localStorage.removeItem('persistentOrderState');
        } else {
            return { activeOrder: { order: orderState.persistentOrder } };
        }
    }
    return {};
}

const store = createStore(rootReducer, buildPersistentState(), composeWithDevTools(applyMiddleware(thunkMiddleware)));

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
