import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon, Input, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import OrderOverview from '../../components/OrderOverview/OrderOverview';
import { getActiveOrder, getOrders, getOrdersIsLoading, getUser } from '../../redux/reducers';
import {
    FLOORBALL_COLOR_EMPTY_RED,
    FloorballColor,
    FloorballState,
    Order,
} from '../../ressources/types/floorball.types';
import './orders.scss';
import { fetchOrder, fetchOrders } from '../../redux/actions';
import ColorPickerModal from '../../components/ColorPicker/ColorPickerModal/ColorPickerModal';
import { filterOrders } from '../../utils/filterHelper';

function Orders({ orders, activeOrder, isLoading, user, fetchOrders, fetchOrder }: PropsFromRedux) {
    const { t } = useTranslation();
    const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
    const [displayedOrders, setDisplayedOrders] = useState<Order[]>([]);
    const [filterInput, setFilterInput] = useState<string>('');
    const [filterStateClosed, setFilterStateClosed] = useState<boolean>(false);
    const [filterStateReady, setFilterStateReady] = useState<boolean>(false);
    const [filterStateProduction, setFilterStateProduction] = useState<boolean>(false);
    const [filterAmount, setFilterAmount] = useState<number>(1);
    const [filterColor, setFilterColor] = useState<FloorballColor | null>(null);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [isWaitingForUser, setIsWaitingForUser] = useState<boolean>(true);
    const PAGE_SIZE = 8;

    function fetchOrdersByContext() {
        if (user.isGuest) {
            if(activeOrder != null) {
                fetchOrder(activeOrder.salesDocumentId);
            }
        } else {
            fetchOrders();
        }
    }

    useEffect(() => {
        // Makes sure that the loading indicator is visible while waiting for user initialisation.
        const waiting = setInterval(() => {
            setIsWaitingForUser(false);
        }, 300);
        return () => {
            clearInterval(waiting);
        };
    }, []);
    
    useEffect(() => {
        if (user == null) return;
        if (orders == null) {
            fetchOrdersByContext();
        }
        const polling = setInterval(() => {
            fetchOrdersByContext();
        }, 5000);
        return () => {
            clearInterval(polling);
        };
    }, [user, orders]);

    useEffect(() => {
        if (orders != null) {
            setupDisplayedOrders();
        }
        if (filterColor === FLOORBALL_COLOR_EMPTY_RED) {
            setFilterColor(null);
        }
    }, [orders, filterInput, filterAmount, filterStateClosed, filterStateReady, filterStateProduction, filterColor, hasMore]);

    useEffect(() => {
        setHasMore(filteredOrders.length > displayedOrders.length);
    }, [displayedOrders]);

    function sortByCreatedAt(orders: Order[]): Order[] {
        return orders.sort((a: Order, b: Order) => b.createdAt.toString().localeCompare(a.createdAt.toString()));
    }

    function handleFilterChange(event: any) {
        setFilterInput(event.target.value);
    }

    function handleFilterState(state: FloorballState) {
        setupDisplayedOrders();
        switch (state) {
            case FloorballState.CLOSED:
                setFilterStateClosed(!filterStateClosed);
                break;
            case FloorballState.READY:
                setFilterStateReady(!filterStateReady);
                break;
            case FloorballState.PRODUCTION:
                setFilterStateProduction(!filterStateProduction);
                break;
        }
    }

    function setupDisplayedOrders() {
        setDisplayedOrders(applyFilters(sortByCreatedAt(orders)));
    }

    function applyFilters(orders: Order[]) {
        const filtered = filterOrders(orders, filterInput, filterStateClosed, filterStateReady, filterStateProduction, filterColor);
        setFilteredOrders(filtered);
        return filtered.slice(0, filterAmount * PAGE_SIZE);
    }

    function toggleFilter() {
        const input = document.getElementById('filter-input');
        if (input && !showFilter) {
            input.focus();
        }
        setShowFilter(!showFilter);
    }

    function setVisibleClass(condition: boolean) {
        return !condition ? 'visible' : '';
    }

    function setActiveClass(condition: boolean) {
        return condition ? 'active' : '';
    }

    function renderOrderOverviews() {
        const orderOverviews: any = [];
        displayedOrders.forEach(item => {
            orderOverviews.push(<OrderOverview key={item.salesDocumentId} order={item} />);
        });
        if (orderOverviews.length > 0) {
            return orderOverviews;
        }
        return <h2>{t('pages-orders-noOrders')}</h2>;
    }

    return (
        <div id='orderswrapper'>
            <h1>{t('pages-orders-title')}</h1>
            {( isWaitingForUser || isLoading) ? (
                <div className='orders-loader'>
                    <Loader active inline='centered' size='huge' />
                </div>
            ) : (
                <div className='order-list'>
                    <div className='filter'>
                        <div className={`filters ${setVisibleClass(showFilter)}`}>
                            <div className='filter-buttons'>
                                <div className='color-filter'>
                                    <ColorPickerModal action={setFilterColor} active={filterColor || 'empty_red'}
                                                      enableAll={true} isLeft={true} isRight={true}/>
                                </div>
                                <button
                                    aria-label='toggle-closed'
                                    className={`${setActiveClass(filterStateClosed)} closed`}
                                    onClick={() => handleFilterState(FloorballState.CLOSED)}>
                                    <Icon name='check' />
                                </button>
                                <button
                                    aria-label='toggle-ready'
                                    className={`${setActiveClass(filterStateReady)} ready`}
                                    onClick={() => handleFilterState(FloorballState.READY)}>
                                    <Icon name='qrcode' />
                                </button>
                                <button
                                    aria-label='toggle-production'
                                    className={`${setActiveClass(filterStateProduction)} production`}
                                    onClick={() => handleFilterState(FloorballState.PRODUCTION)}>
                                    <Icon name='cogs' />
                                </button>
                            </div>
                            <Input
                                type='text'
                                name='filter'
                                id='filter-input'
                                placeholder={t('pages-orders-filterPlaceholder')}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <Icon name='filter' onClick={toggleFilter} />
                    </div>
                    <div className='orders'>{renderOrderOverviews()}</div>
                    {hasMore && (
                        <div className='show-more'>
                            <button onClick={() => setFilterAmount(filterAmount + 1)}>{t('button-showMore')}</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    orders: getOrders(state),
    activeOrder: getActiveOrder(state),
    isLoading: getOrdersIsLoading(state),
    user: getUser(state),
});

const mapDispatchToProps = { fetchOrders, fetchOrder };

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Orders);
