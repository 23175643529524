/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import { User, UserState } from '../ressources/types/auth.types';
import {
    Floorball,
    FLOORBALL_COLOR_EMPTY_RED,
    FloorballColor,
    Order, Status,
    StockType,
} from '../ressources/types/floorball.types';
import { equalOrders, equalStock } from '../utils/equalsHelper';
import { formatDecimalPlaces } from '../utils/formatHelper';

const initialUserState: UserState = {
    user: null,
    isLoading: false,
    isAuthenticated: false,
    error: null,
};

export interface UserAction {
    type: string;
    user?: User;
    isLoading?: boolean;
    isAuthenticated?: boolean;
    error?: Error;
}

function user(state = initialUserState, action: UserAction) {
    switch (action.type) {
        case 'AUTHENTICATION_STARTED':
            return { ...state, isLoading: true };
        case 'AUTHENTICATION_SUCCEEDED':
            return { ...state, user: action.user, isLoading: false, isAuthenticated: true, error: null };
        case 'AUTHENTICATION_FAILED':
            return { ...state, isLoading: false, error: action.error };
        case 'SIGNOUT':
            return {
                ...state,
                user: null,
                isLoading: false,
                isAuthenticated: false,
                error: null,
            };
        case 'FETCH_ACCOUNT_DETAILS_STARTED':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_ACCOUNT_DETAILS_SUCCEEDED':
            return {
                ...state,
                isLoading: false,
                user: action.user,
                isAuthenticated: true,
            };
        case 'FETCH_ACCOUNT_DETAILS_FAILED':
            return { ...state, isLoading: false, isAuthenticated: false, user: null, error: null };
        default:
            return state;
    }
}

const initialConfigurationState = {
    leftColor: FLOORBALL_COLOR_EMPTY_RED,
    rightColor: FLOORBALL_COLOR_EMPTY_RED,
    error: null,
};

export interface ConfigurationAction {
    type: string;
    leftColor?: FloorballColor;
    rightColor?: FloorballColor;
    error?: Error;
}

function configuration(state = initialConfigurationState, action: ConfigurationAction) {
    switch (action.type) {
        case 'SET_LEFTCOLOR_SUCCEEDED':
            return { ...state, leftColor: action.leftColor };
        case 'SET_RIGHTCOLOR_SUCCEEDED':
            return { ...state, rightColor: action.rightColor };
        case 'RESET_CONFIGURATION':
            return initialConfigurationState;
        case 'CONFIGURATION_FAILED':
            return { ...state, error: action.error };
        default:
            return state;
    }
}

const initialOrdersState = {
    items: null,
    isLoading: false,
    error: null,
};

export interface OrdersAction {
    type: string;
    items?: Order[];
    isLoading?: boolean;
    error?: Error;
}

function orders(state = initialOrdersState, action: OrdersAction) {
    switch (action.type) {
        case 'FETCH_ORDERS_STARTED':
            if (!state.items) {
                return { ...state, isLoading: true };
            }
            return state;
        case 'FETCH_ORDERS_SUCCEEDED':
            if (action.items && !equalOrders(state.items, action.items)) {
                return { ...state, items: action.items, isLoading: false };
            }
            return { ...state, isLoading: false };
        case 'FETCH_ORDERS_FAILED':
            return { ...state, isLoading: false, error: action.error };
        case 'RESET_ORDERS':
            return { ...state, items: null };
        default:
            return state;
    }
}

const initialOrderState = {
    order: null,
    isLoading: false,
    error: null,
};

export interface ActiveOrderAction {
    type: string;
    order?: Order;
    isLoading?: boolean;
    error?: Error;
}

function activeOrder(state = initialOrderState, action: ActiveOrderAction) {
    switch (action.type) {
        case 'CREATE_ORDER_STARTED':
            return { ...state, isLoading: true };
        case 'CREATE_ORDER_SUCCEEDED':
            return { ...state, order: action.order, isLoading: false };
        case 'RESET_ACTIVE_ORDER':
            return initialOrderState;
        case 'CREATE_ORDER_FAILED':
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

const initialStocksState = {
    items: null,
    isLoading: false,
    error: null,
};

export interface StocksAction {
    type: string;
    items?: StockType[];
    isLoading?: boolean;
    error?: Error;
}

function stocks(state = initialStocksState, action: StocksAction) {
    switch (action.type) {
        case 'FETCH_STOCK_STARTED':
            if (!state.items) {
                return { ...state, isLoading: true };
            }
            return state;
        case 'FETCH_STOCK_SUCCEEDED':
            if (action.items && !equalStock(state.items, action.items)) {
                return { ...state, items: action.items, isLoading: false };
            }
            return { ...state, isLoading: false };
        case 'FETCH_STOCK_FAILED':
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

const initialFLoorballDataState = {
    data: null,
    isLoading: false,
    error: null,
};

export interface FloorballDataAction {
    type: string;
    data?: Floorball;
    isLoading?: boolean;
    error?: Error;
}

function floorballData(state = initialFLoorballDataState, action: FloorballDataAction) {
    switch (action.type) {
        case 'FETCH_FLOORBALLDATA_STARTED':
            return { ...state, isLoading: true, error: null };
        case 'FETCH_FLOORBALLDATA_SUCCEEDED':
            const data = formatDecimalPlaces(action.data);
            return { ...state, data, isLoading: false, error: null };
        case 'FETCH_FLOORBALLDATA_FAILED':
            return { ...state, isLoading: false, error: action.error };
        case 'RESET_FLOORBALLDATA':
            return { ...state, data: null, error: null };
        default:
            return state;
    }
}

const initialAppConfigState = {
    orderService: null,
    isLoading: false,
    error: null,
};

export interface AppConfigAction {
    type: string;
    status?: Status;
    isLoading?: boolean;
    error?: Error;
}

function appConfig(state = initialAppConfigState, action: AppConfigAction) {
    switch (action.type) {
        case 'FETCH_APP_CONFIG_STARTED':
            return { ...state, isLoading: true };
        case 'FETCH_APP_CONFIG_SUCCEEDED':
            return { ...state, status: action.status, isLoading: false };
        case 'FETCH_APP_CONFIG_FAILED':
            return { ...state, isLoading: false, error: action.error };
        case 'PUT_APP_CONFIG_STARTED':
            return { ...state, isLoading: true };
        case 'PUT_APP_CONFIG_SUCCEEDED':
            return { ...state, status: action.status, isLoading: false };
        case 'PUT_APP_CONFIG_FAILED':
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

const reducers = combineReducers({
    user,
    configuration,
    orders,
    activeOrder,
    stocks,
    floorballData,
    appConfig,
});

export function getUser(state: any): User {
    return state.user.user;
}

export function getUserIsLoading(state: any): boolean {
    return state.user.isLoading;
}

export function getIsAuthenticated(state: any): boolean {
    return state.user.isAuthenticated;
}

export function getUserError(state: any): Error {
    return state.user.error;
}

export function getConfigurationLeftColor(state: any): StockType {
    return state.configuration.leftColor;
}

export function getConfigurationRightColor(state: any): StockType {
    return state.configuration.rightColor;
}

export function getOrders(state: any): Order[] {
    return state.orders.items;
}

export function getOrdersIsLoading(state: any): boolean {
    return state.orders.isLoading;
}

export function getOrdersError(state: any): Error {
    return state.orders.error;
}

export function getActiveOrder(state: any): Order {
    return state.activeOrder.order;
}

export function getActiveOrderIsLoading(state: any): boolean {
    return state.activeOrder.isLoading;
}

export function getActiveOrderError(state: any): Error {
    return state.activeOrder.error;
}

export function getStocks(state: any): StockType[] {
    return state.stocks.items;
}

export function getStocksIsLoading(state: any): boolean {
    return state.stocks.isLoading;
}

export function getFloorballData(state: any): Floorball {
    return state.floorballData.data;
}

export function getFloorballDataIsLoading(state: any): boolean {
    return state.floorballData.isLoading;
}

export function getFloorballDataError(state: any): Error {
    return state.floorballData.error;
}

export function getAppConfig(state: any): Status {
    return state.appConfig.status;
}

export function getAppConfigIsLoading(state: any): boolean {
    return state.appConfig.isLoading;
}

export default reducers;

export {
    user as testUser,
    configuration as testConfiguration,
    orders as testOrders,
    activeOrder as testActiveOrder,
    stocks as testStocks,
    floorballData as testFloorballData,
    appConfig as testAppConfig,
};
