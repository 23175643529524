import { Order, StockType } from "../ressources/types/floorball.types";

export function equalOrders(orders1: Order[] | null, orders2: Order[] | null): boolean {   
    if(orders1 && orders2) {
        orders1.sort((a: Order, b: Order) => b.salesDocumentId > a.salesDocumentId ? 1 : -1);
        orders2.sort((a: Order, b: Order) => b.salesDocumentId > a.salesDocumentId ? 1 : -1);
        if(!equalLength(orders1, orders2)) {
            return false;            
        }
        for(let i = 0; i < orders1.length; i++ ) {
            if(!equalOrder(orders1[i], orders2[i])) {
                return false;
            }
        }
        return true;
    }
    return orders1 === orders2;
}

export function equalOrder(order1: Order, order2: Order): boolean {
    return order1.salesDocumentId === order2.salesDocumentId && order1.state === order2.state;
}

export function equalStock(stock1: StockType[] | null, stock2: StockType[] | null): boolean {
    if(stock1 && stock2) {
        stock1.sort((a: StockType, b: StockType) => b.color.name.localeCompare(a.color.name));
        stock2.sort((a: StockType, b: StockType) => b.color.name.localeCompare(a.color.name));
        if(!equalLength(stock1, stock2)) {
            return false;
        }
        for(let i = 0; i < stock1.length; i++ ) {
            if(stock1[i].color.name !== stock2[i].color.name || stock1[i].amount !== stock2[i].amount) {
                return false;
            }
        }
        return true;
    }
    return stock1 === stock2;
}

export function equalLength(obj1: object[] | null, obj2: object[] | null): boolean {
    return obj1?.length === obj2?.length;
}