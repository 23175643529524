import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { authenticateToken } from '../../redux/actions';
import { getIsAuthenticated, getUserError } from '../../redux/reducers';

function GuestLogin({ isAuthenticated, authError, authenticateToken }: PropsFromRedux) {
    const {t} = useTranslation();
    const { search } = useLocation();
    const navigate = useNavigate();
    const secondsUntilRedirect = 5;

    function redirectWithDelay(delayInMillis: number) {
        setTimeout(() => {
            navigate('/');
        }, delayInMillis);
    }

    if (isAuthenticated) {
        redirectWithDelay(secondsUntilRedirect * 1000);
        return <Message content={t("pages.guestLogin.loginSuccessful")} />;
    }
    if (authError) {
        return <Message error content={t("pages.guestLogin.loginError")}/>;
    }
    authenticateToken(new URLSearchParams(search).get('token') || '');
    return <Message content={t("pages.guestLogin.loginPending")} />;
}

// REDUX DEFINITIONS
type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect((state: any) => ({
    isAuthenticated: getIsAuthenticated(state),
    authError: getUserError(state),
}), { authenticateToken });

export default connector(GuestLogin);
