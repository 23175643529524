import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import OrderNavigator from '../../components/OrderNavigator/OrderNavigator';
import { getActiveOrder } from '../../redux/reducers';
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation';
import './Configurator.scss';

function Configurator({ activeOrder }: PropsFromRedux) {
    const { t } = useTranslation();
    const [confirmOrder, setConfirmOrder] = useState<boolean>(false);

    useEffect(() => {
        if (activeOrder != null) {
            setConfirmOrder(true);
        }
    }, [activeOrder, confirmOrder, setConfirmOrder]);

    if (confirmOrder) {
        return <OrderConfirmation />;
    }
    return (
        <div id='configurator'>
            <h1>{t('pages.configurator.title')}</h1>
            <ColorPicker />
            <OrderNavigator />
        </div>
    );
}

// REDUX DEFINITIONS
type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect((state: any) => ({
    activeOrder: getActiveOrder(state),
}), {});

export default connector(Configurator);
