import * as z from 'zod';

const Host = z.string().nonempty();
const Boolean = z.coerce.boolean();

export const EnvFileDeclaration = z.object({
    REACT_APP_BACKEND_URL: Host,
    REACT_APP_SHOW_DEBUG_RESET_ORDER_BUTTON: Boolean,
});

export type EnvFileDeclarationType = z.infer<typeof EnvFileDeclaration>;
