import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StockType } from '../../../ressources/types/floorball.types';
import Color from '../Color/Color';

import {
    getConfigurationLeftColor,
    getConfigurationRightColor,
    getStocks,
    getStocksIsLoading,
} from '../../../redux/reducers';
import './colorPalette.scss';

function ColorPalette({ props, stocks, isLoading }: PropsFromRedux) {
    const { t } = useTranslation();
    const [colors, setColors] = useState<any[]>([]);
    const [outOfStock] = useState<boolean>(false);


    function hexToBrightness(hexString: string) : number {
        const conversionValues = [0.299, 0.587, 0.114];
        const hexValue = hexString.slice(1, hexString.length);
        let brightness = 0;
        for(let i = 0; i < 3; i++) {
            const hexByte = hexValue.substring(2 * i, 2 * (i + 1));
            const hexByteAsDecimal = parseInt(hexByte, 16);
            brightness += conversionValues[i] * hexByteAsDecimal;
        }
        return brightness;
    }

    useEffect(() => {
        if (stocks != null) {
            setColors([]);
            stocks.sort((a, b) => hexToBrightness(a.color.hexCode) - hexToBrightness(b.color.hexCode)); // Ensure consistent UI ordering
            stocks.forEach((item: StockType) => {
                const isEnabled = props.enableAll || item.amount > item.minimumNeededInStock;
                const isActive = item.color.name === props.active;
                const colorElement = <Color key={item.materialNumber} materialData={item}
                                            setColor={props.action} disabled={!isEnabled} active={isActive}
                                            isLeft={props.isLeft} isRight={props.isRight}/>;
                setColors((colors) => [...colors, colorElement]);
            });
        }
        // TODO: Ensure the "Out of Stock" message shows if all stocks are 0
    }, [props, stocks]);

    if (isLoading) {
        return (
            <div className='colorpalette loading'>
                <Loader active inline='centered' size='huge' />
            </div>
        );
    }

    return (
        <div className='colorpalette'>
            {colors}
            {outOfStock && <div className='out-of-stock'>{t('pages-configurator-outOfStock')}</div>}
        </div>
    );
}

const mapStateToProps = (state: any, props: { enableAll?: boolean; active: string; action: any, isLeft: boolean, isRight: boolean}) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    stocks: getStocks(state),
    isLoading: getStocksIsLoading(state),
    props,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ColorPalette);
