export function formateDateFromTimestamp(timestamp : number) {
    if(timestamp.toString().length !== 10) {
        return "--.--.----, --:--";
    }
    const dateObj = new Date(timestamp * 1000);
    const date = dateObj.toLocaleDateString('de-CH');
    const hours = `0${  (dateObj.getUTCHours() + 1) % 24}`;
    const minutes = `0${  dateObj.getMinutes()}`;
    return `${date  }, ${  hours.substr(-2)  }:${  minutes.substr(-2)}`;
}

export function formatDateFromDateString(dateString: string) {
    return formateDateFromTimestamp(parseInt((Date.parse(dateString) / 1000).toFixed(0), 10));
}