import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    fetchStocks,
    resetConfiguration,
    setConfigurationLeftColor,
    setConfigurationRightColor,
} from '../../redux/actions';
import { getConfigurationLeftColor, getConfigurationRightColor, getStocks } from '../../redux/reducers';
import { StockType } from '../../ressources/types/floorball.types';
import Floorball from '../Floorball/Floorball';
import ColorPalette from './ColorPalette/ColorPalette';
import './colorpicker.scss';
import ColorPickerModal from './ColorPickerModal/ColorPickerModal';

const Floorball3D = lazy(() => import('../Floorball3D/Floorball3D'));

function ColorPicker({
                         leftColor,
                         rightColor,
                         stocks,
                         fetchStocks,
                         setConfigurationLeftColor,
                         setConfigurationRightColor,
                         resetConfiguration,
                     }: PropsFromRedux) {
    const [mobileView, setMobileView] = useState<boolean>(false);
    const [is3D, setIs3D] = useState<boolean>(false);

    useEffect(() => {
        handleResize();
        fetchStocks();
        const polling = setInterval(() => {
            fetchStocks();
        }, 5000);

        if (stocks) {
            stocks.forEach((stock: StockType) => {
                if (stock.amount <= 1 && (stock.color?.name === leftColor.color?.name || stock.color?.name === rightColor.color?.name)) {
                    resetConfiguration();
                }
            });
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(polling);
        };
    }, [mobileView, setMobileView, leftColor, rightColor, stocks]);

    function handleResize() {
        if (window.innerWidth <= 900) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    }

    function toggle3D() {
        setIs3D(!is3D);
    }

    function floorballToggle() {
        return (
            <div className='floorball-toggle'>
                <img src='/img/360-degree.svg' alt='360 Degree Icon' />
                <div className='ui toggle checkbox'>
                    <input name='toggle3d' placeholder='toggle3d' onChange={() => toggle3D()} type='checkbox' />
                    <label></label>
                </div>
            </div>
        );
    }

    function floorballWrapper() {
        return (
            <div id='floorballwrapper' style={{ backgroundImage: 'url(\'/shadow.png\')' }}>
                {is3D ? (
                    <Suspense fallback={null}>
                        <Floorball3D leftColor={leftColor.color?.name} rightColor={rightColor.color?.name} />
                    </Suspense>
                ) : (
                    <Floorball leftColor={leftColor.color?.name} rightColor={rightColor.color?.name} />
                )}
            </div>
        );
    }

    if (mobileView) {
        return (
            <div>
                {floorballToggle()}
                <div id='colorpicker' className='mobile'>
                    {floorballWrapper()}
                    <div id='mobileColors'>
                        <ColorPickerModal action={setConfigurationLeftColor} active={leftColor.color?.name}
                                          initialColor={leftColor.color?.name} isLeft={true} isRight={false}/>
                        <ColorPickerModal action={setConfigurationRightColor} active={rightColor.color?.name}
                                          initialColor={rightColor.color?.name} isLeft={false} isRight={true}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {floorballToggle()}
            <div id='colorpicker'>
                <div id='firstcolor'>
                    <ColorPalette action={setConfigurationLeftColor} active={leftColor.color?.name} isLeft={true} isRight={false}/>
                </div>
                {floorballWrapper()}
                <div id='secondcolor'>
                    <ColorPalette action={setConfigurationRightColor} active={rightColor.color?.name} isLeft={false} isRight={true}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    stocks: getStocks(state),
});

const mapDispatchToProps = { setConfigurationLeftColor, setConfigurationRightColor, fetchStocks, resetConfiguration };

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ColorPicker);
