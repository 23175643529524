import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Divider, Header, Table } from 'semantic-ui-react';
import { fetchFloorballData } from '../../redux/actions';
import { getFloorballData } from '../../redux/reducers';
import { Floorball as FloorballType } from '../../ressources/types/floorball.types';
import { formatDateFromDateString } from '../../utils/dateHelper';
import Floorball from '../Floorball/Floorball';
import './floorballData.scss';
import FloorballHalfData from './FloorballHalfData/FloorballHalfData';

function FloorballData({ floorballData, fetchFloorballData }: PropsFromRedux) {
    const [floorball, setFloorball] = useState<FloorballType | null>(null);

    useEffect(() => {
        if (!floorball) {
            setFloorball(floorballData);
        } else {
            fetchFloorballData(2);
        }
        return () => setFloorball(null);
    }, [floorballData]);

    return (
        <>
            {floorball?.productionData && (
                <>
                    <Divider horizontal>
                        <Header as='h2'>Floorball</Header>
                    </Divider>
                    <h3>Serial Number</h3>
                    <p>{floorball?.productionData.serialNo}</p>
                    <h3>Production Date</h3>
                    <p>{formatDateFromDateString(floorball?.productionData._time)}</p>
                    <div className='floorball-data'>
                        <div className='floorball'>
                            <Floorball
                                leftColor={floorball?.leftHalf.color.name || 'empty_red'}
                                rightColor={floorball?.rightHalf.color.name || 'empty_red'}
                            />
                        </div>
                        <div className='data'>
                            <h3>Welding</h3>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Heating Time</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.heatingTime}s</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Changeover Time</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.changeoverTime}s</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Welding Temperature</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.weldingTemperature} °C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Welding Time</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.weldingTime}s</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <h3>Environment</h3>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Temperature</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.temperature} °C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Humidity</Table.Cell>
                                        <Table.Cell>{floorball?.productionData.humidity} %</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                    <Divider horizontal>
                        <Header as='h2'>First Floorball Half</Header>
                    </Divider>
                    <FloorballHalfData floorballHalf={floorball.leftHalf} side='left' />
                    <Divider horizontal>
                        <Header as='h2'>Second Floorball Half</Header>
                    </Divider>
                    <FloorballHalfData floorballHalf={floorball.rightHalf} side='right' />
                </>
            )}
        </>
    );
}

const mapStateToProps = (state: any) => ({
    floorballData: getFloorballData(state),
});

const mapDispatchToProps = {
    fetchFloorballData,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FloorballData);
