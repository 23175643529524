import React, { lazy, Suspense } from 'react';
import './pageNotFound.scss';
import { useTranslation } from 'react-i18next';

const Floorball3D = lazy(() => import('../../components/Floorball3D/Floorball3D'));

function PageNotFound() {
    const {t} = useTranslation();

    return (
        <div id='page-not-found'>
            <h1>{t("pages-pageNotFound-title")}</h1>
            <h2>{t("pages-pageNotFound-subtitle")}</h2>
            <Suspense fallback={null}>
                <Floorball3D leftColor='white' rightColor='blue' />
            </Suspense>
        </div>
    );
}

export default PageNotFound;
