import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { authenticate } from '../../redux/actions';
import { getIsAuthenticated, getUserError } from '../../redux/reducers';

function Login({ isAuthenticated, authError, authenticate }: PropsFromRedux) {
    const { t } = useTranslation();
    const [customerNumber, setCustomerNumber] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        } else if (authError) {
            setError(true);
        }
    }, [isAuthenticated, authError]);

    const onSubmit = (event: any) => {
        event.preventDefault();
        setLoading(true);
        authenticate(customerNumber, password).then(() => {
            setLoading(false);
        });
    };

    return (
        <div id='login'>
            <h1>{t('pages-login-title')}</h1>
            <div className='login-form'>
                <Form size='large' error={error}>
                    <Form.Field>
                        <Input
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomerNumber(event.target.value)}
                            icon='user'
                            iconPosition='left'
                            placeholder={t('placeholder-customer-number')}
                            value={customerNumber}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                            icon='lock'
                            iconPosition='left'
                            placeholder={t('placeholder-password')}
                            type='password'
                            value={password}
                        />
                    </Form.Field>
                    <Button loading={loading} 
                            onClick={onSubmit} 
                            size='large'
                            fluid={true}
                            content={t('pages-login-button')}
                            disabled={loading}/>
                    <Message error content={t('pages-login-error')} />
                </Form>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: getIsAuthenticated(state),
    authError: getUserError(state),
});

const mapDispatchToProps = {
    authenticate,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
