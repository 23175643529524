import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import './Home.scss';

function Home() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById('root')?.classList.add('home');
        return () => {
            document.getElementById('root')?.classList.remove('home');
        };
    }, []);

    const redirect = (target: string) => {
        navigate(target);
    };

    return (
        <div id='home'>
            <h1>{t("pages.home.title")}</h1>
            <h3>{t("pages.home.subtitle")}</h3>
            <div id='topics'>
                <div onClick={() => redirect('/production-data')} className='disabled topic production-data'>
                    <Icon name='info' />
                    <h2>{t("pages.home.topic1Title")}</h2>
                    <p>{t("pages.home.topic1Desc")}</p>
                </div>
                <div onClick={() => redirect('/configurator')} className='topic configurator'>
                    <Icon name='configure' />
                    <h2>{t("pages.home.topic2Title")}</h2>
                    <p>{t("pages.home.topic2Desc")}</p>
                </div>
                <div onClick={() => redirect('/orders')} className='topic orders'>
                    <Icon name='folder' />
                    <h2>{t("pages.home.topic3Title")}</h2>
                    <p>{t('pages.home.topic3Desc')}</p>
                </div>
            </div>
        </div>
    );
}

export default Home;
