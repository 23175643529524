import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, SemanticICONS } from 'semantic-ui-react';
import QRCode from 'qrcode';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FloorballState, Order } from '../../ressources/types/floorball.types';
import Floorball from '../Floorball/Floorball';
import './orderOverview.scss';
import { fetchFloorballData } from '../../redux/actions';

function OrderOverview({ props }: PropsFromRedux) {
    const { t, i18n } = useTranslation();
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [qrCode, setQrCode] = useState<any>(<Icon name='question' />);
    const [iconName, setIconName] = useState<SemanticICONS | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [floorballState, setFloorballState] = useState<string>('');

    useEffect(() => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const date = new Date(props.order.createdAt);
        // @ts-ignore (Due to issues with the single quotes)
        setDate(date.toLocaleDateString(i18n.language, options));

        QRCode.toDataURL(`${props.order.referencedSerialNumber}`, { version: 5 }, (err, url) => {
            setQrCodeUrl(url);
        });

        switch (props.order.state) {
            case FloorballState.CLOSED:
                setIconName('check');
                setTitle(t('order-state-closed'));
                setQrCode(<Icon name={iconName} />);
                setFloorballState('closed');
                break;
            case FloorballState.PRODUCTION:
                setIconName('cogs');
                setTitle(t('order-state-inProduction'));
                setQrCode(<Icon name={iconName} />);
                setFloorballState('inProduction');
                break;
            case FloorballState.READY:
                setIconName('qrcode');
                setTitle(t('order-state-ready'));
                setQrCode(<img src={qrCodeUrl} alt={t('order-qrcode-altTag')} />);
                setFloorballState('readyForPickup');
                break;
            case FloorballState.QUEUE:
                setIconName('clock outline');
                setTitle(t('order-state-inQueue'));
                setQrCode(<Icon name={iconName} />);
                break;
            default:
                setIconName('question');
                setTitle(t('order-state-closed'));
        }
    }, [setQrCodeUrl, iconName, title]);

    function showProductionData() {
        /*
        *   fetchFloorballData(props.order.floorball?.leftHalf.materialNumber);
        *   history.push('/production-data');
        * */
    }

    return (
        <div className={`order-overview ${floorballState}`}>
            <Floorball leftColor={props.order.floorball?.leftHalf.color.name}
                       rightColor={props.order.floorball?.rightHalf.color.name} />
            <div className='order-description' onClick={() => setOpenDetails(true)}>
                <div>
                    <h2>{title}</h2>
                    <h3>{date}</h3>
                    {iconName !== undefined && <Icon name={iconName} />}
                </div>
            </div>
            {(props.order.amount > 1) && 
                    <div className='order-amount'>{props.order.amount}</div>
            }
            <Modal onClose={() => setOpenDetails(false)} open={openDetails} className='order-detail-modal'>
                <Modal.Header>
                    {t('order-title')}: {props.order.floorball?.materialNumber} / {props.order.referencedSerialNumber}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className='qrcode'>
                            {qrCode}
                            <h2>{title}</h2>
                            <h3>
                                {t('order-dateLabel')} {date}
                            </h3>
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='disabled' color='blue' onClick={() => showProductionData()} disabled>
                        {t('button-productionData')}
                    </Button>
                    <Button color='red' onClick={() => setOpenDetails(false)}>
                        {t('button-close')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state: any, props: { order: Order }) => ({
    props,
});

const mapDispatchToProps = {
    fetchFloorballData,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrderOverview);
