import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchAppConfig, getAccountDetails, setAppConfig } from '../../redux/actions';
import { getAppConfig, getUser } from '../../redux/reducers';
import './adminSettings.scss';
import EnvVariableHelper from "../../utils/envVariableHelper";

const showDebugResetOrderButton = EnvVariableHelper.VARIABLES.REACT_APP_SHOW_DEBUG_RESET_ORDER_BUTTON;

function AdminSettings({ user, appConfig, getAccountDetails, fetchAppConfig }: PropsFromRedux) {
    const {t} = useTranslation();
    const [enabled, setEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (!user) {
            getAccountDetails();
        }
    }, []);

    useEffect(() => {
        const polling = setInterval(() => {
            fetchAppConfig();
        }, 10000);
        if (user && appConfig == null) {
            fetchAppConfig();
        }
        setEnabled(appConfig?.isSystemAvailable);
        return () => {
            clearInterval(polling);
        };
    }, [user, appConfig, enabled]);

    function toggleEnabled() { }

    return (
        <div className='admin-settings'>
            {user?.admin && (
                <div className='orders-enabled'>
                    <label>{t("adminSettings-ordersEnabled")}</label>
                    <div className='ui toggle checkbox'>
                        <input onChange={toggleEnabled} type='checkbox' name='public' checked={enabled} />
                        <label></label>
                    </div>
                </div>
            )}
            <div className='factory'>
                <label>{t("adminSettings-factoryStatus")} </label>
                <div className={`factory-available ${appConfig?.isSystemAvailable ? 'available' : 'unavailable'}`}>
                    {appConfig?.isSystemAvailable ? t("adminSettings-factoryStatusAvailable") : t("adminSettings-factoryStatusUnavailable")}
                </div>
            </div>
            {showDebugResetOrderButton && 
                <button onClick={() => { 
                    localStorage.removeItem('persistentOrderState'); 
                    window.location.reload(); }}>*DEBUG*: Reset order</button>}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    user: getUser(state),
    appConfig: getAppConfig(state),
});

const mapDispatchToProps = {
    getAccountDetails,
    fetchAppConfig,
    setAppConfig,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AdminSettings);
