import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './backButton.scss';
import { useTranslation } from 'react-i18next';

function BackButton() {
    const {t} = useTranslation();
    const location = useLocation().pathname;
    const navigate = useNavigate();
    if (location === '/') {
        return null;
    }
    return (
        <button onClick={() => navigate(-1)} className='back-button'>
            <Icon name='arrow left' />
            {t("button-back")}
        </button>
    );
}

export default BackButton;
