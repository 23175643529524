import { FloorballColor, FloorballState, Order } from "../ressources/types/floorball.types";

export function filterOrders(orders: Order[], filterInput: string, filterStateClosed: boolean, filterStateReady: boolean, filterStateProduction: boolean, filterColor: FloorballColor | null) {
    let filtered = orders;
        if (filterInput !== '') {
            filtered = orders.filter((order: Order) => {
                const pattern = new RegExp(`${filterInput}`);
                return (
                    order.floorball.materialNumber.match(pattern) ||
                    order.floorball.leftHalf.materialNumber.match(pattern) ||
                    order.floorball.rightHalf.materialNumber.match(pattern)
                );
            });
        }
        if (filterStateClosed || filterStateReady || filterStateProduction) {
            filtered = filtered.filter((order: Order) => {
                return (
                    (filterStateClosed && order.state === FloorballState.CLOSED) ||
                    (filterStateReady && order.state === FloorballState.READY) ||
                    (filterStateProduction && order.state === FloorballState.PRODUCTION)
                );
            });
        }
        if (filterColor !== null) {
            filtered = filtered.filter((order: Order) => {
                return order.floorball.leftHalf.color.name === filterColor || order.floorball.rightHalf.color.name === filterColor;
            });
        }
    return filtered;
}