import React, { useRef, useState } from 'react';
import { Image, Loader } from 'semantic-ui-react';
import './floorball.scss';

function Floorball(props: { leftColor: string; rightColor: string }) {
    const [loading, setLoading] = useState<boolean>(true);
    const imageSource = '/img/floorball/';
    const imageType = '.png';
    const defaultImage = 'empty_red'
    const counter = useRef(0);

    function getImageSource(colorName: string, prefix: string) : string {
        if(colorName != null && colorName !== ""){
            return imageSource + prefix + colorName + imageType;
        }
        return imageSource + prefix + defaultImage + imageType;
    }

    function imageLoaded() {
        counter.current += 1;
        if (counter.current >= 2) {
            setLoading(false);
        }
    }
    return (
        <>
            <Loader size='huge' style={{ display: loading ? 'block' : 'none' }} />
            <div className='floorball' style={{ display: loading ? 'none' : 'block' }}>
                <Image className='floorball-right' src={ getImageSource(props.leftColor, 'r_') } alt='Right floorball half' onLoad={() => imageLoaded()} />
                <Image className='floorball-left' src={ getImageSource(props.rightColor, 'l_') } alt='Left floorball half' onLoad={() => imageLoaded()} />
            </div>
        </>
    );
}

export default Floorball;
