import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminSettings from '../AdminSettings/AdminSettings';
import './footer.scss';

function Footer() {
    const {t} = useTranslation();

    return (
        <footer id='footerwrapper'>
            <div id='footer'>
                <AdminSettings />
                <div id='about'>
                    <a href="https://www.ost.ch/de/die-ost/organisation/medien/smart-factory-an-der-ost-volldigitalisierte-fertigung-fuer-die-zukunft" target="_blank" rel="noreferrer noopener">
                        {t("components.footer.about")}
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
