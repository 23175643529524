import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Loader, Button, Form, Input, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FloorballData from '../../components/FloorballData/FloorballData';
import { fetchFloorballData, resetFloorballData } from '../../redux/actions';
import { getFloorballData, getFloorballDataError, getFloorballDataIsLoading } from '../../redux/reducers';
import './productionData.scss';

function ProductionData({ floorballData, isLoading, error, fetchFloorballData, resetFloorballData }: PropsFromRedux) {
    const {t} = useTranslation();
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setHasError(error !== null);
        setLoading(isLoading);
    }, [floorballData, isLoading, error]);

    useEffect(() => {
        return () => {
            resetFloorballData();
        };
    }, []);

    function onSubmit(event: any) {
        event.preventDefault();
        setHasError(false);
        resetFloorballData();
        fetchFloorballData(parseInt(serialNumber, 10));
    }

    return (
        <>
            <div id='production-data'>
                <h1>{t("pages-productionData-title")}</h1>
                <div className='search-serialnumber'>
                    <Form>
                        <Form.Field>
                            <Input
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setSerialNumber(event.target.value)}
                                icon='hashtag'
                                iconPosition='left'
                                placeholder={t("pages-productionData-inputPlaceholder")}
                                value={serialNumber}
                            />
                        </Form.Field>
                        <Button
                            icon='search'
                            loading={loading}
                            onClick={onSubmit}
                            size='large'
                            fluid={true}
                            content={t("pages-productionData-button")}
                        />
                    </Form>
                </div>
                <Message error hidden={!hasError} content={t("error-generic")} />
                {isLoading && <Loader active size='huge' />}
                {floorballData && (
                    <div className='result'>
                        <FloorballData />
                    </div>
                )}
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    floorballData: getFloorballData(state),
    isLoading: getFloorballDataIsLoading(state),
    error: getFloorballDataError(state),
});

const mapDispatchToProps = {
    fetchFloorballData,
    resetFloorballData,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProductionData);
