import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import './color.scss';
import { StockType } from '../../../ressources/types/floorball.types';
import { getConfigurationLeftColor, getConfigurationRightColor } from '../../../redux/reducers';

function Color({ props, leftColor, rightColor } : PropsFromRedux) {
    const [disabled, setDisabled] = useState<string>('');
    const [active, setActive] = useState<string>('');

    function isActive() {
        return (props.isLeft && props.materialData.materialNumber === leftColor.materialNumber) ||
            (props.isRight && props.materialData.materialNumber === rightColor.materialNumber);
    }

    useEffect(() => {
        setDisabled(props.disabled ? 'disabled' : '');
        setActive(isActive() ? 'active' : '');
    }, [props]);

    function setColor() {
        if (!props.disabled) {
            props.setColor(props.materialData);
            setActive('active');
        }
    }

    return (
        <div className={`colorwrapper ${props.materialData?.color.name} ${disabled} ${active}`}>
            <div className='floorball-color' style={{ backgroundColor: `${props.materialData?.color.hexCode}`}} onClick={setColor}>
            </div>
        </div>
    );
}

// REDUX DEFINITIONS
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Properties {
    materialData: StockType;
    setColor: (material: StockType) => void;
    disabled: boolean;
    active: boolean,
    isLeft: boolean,
    isRight: boolean
};

const connector = connect((state: any, props: Properties) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    props,
}), {});

export default connector(Color);
