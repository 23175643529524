import React, { useEffect } from 'react';
import { Icon, Image } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import './Header.scss';
import { useTranslation } from 'react-i18next';
import { getIsAuthenticated, getUser } from '../../redux/reducers';
import {getAccountDetails, resetOrders, signout} from '../../redux/actions';

function Header({ user, isAuthenticated, signout, getAccountDetails, resetOrders }: PropsFromRedux) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        getAccountDetails();
    }, []);

    function redirect(target: string) {
        navigate(target);
    }

    function handleSignout() {
        signout();
        resetOrders();
        navigate('/');
    }

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <header id='headerwrapper'>
            <div id='header'>
                <div className='left-side'>
                    <div id='logo'>
                        <Link to='/' className='logo'>
                            <Image src='/img/logo/ost_logo.svg' alt='OST Logo' id='logo-image' />
                        </Link>
                    </div>
                    <nav className='translations'>
                        <ul>
                            <li>
                                <button onClick={() => changeLanguage('de')} lang='de'>DE</button>
                            </li>
                            <li>
                                <button onClick={() => changeLanguage('en')} lang='en'>EN</button>
                            </li>
                        </ul>
                    </nav>
                </div>
                {isAuthenticated ? (
                    <div id='user'>
                        {user?.isGuest === false ? 
                            <>
                                {user?.firstname}
                                <Icon onClick={() => redirect('/account')} name='user circle' />
                            </>
                            : t("components.header.guest")}
                        <Icon onClick={() => handleSignout()} name='sign-out'/>
                    </div>
                ) : (
                    <div id='auth'>
                        <button className='inverted' onClick={() => redirect('/login')}>{t('button-login')}</button>
                    </div>
                )}
            </div>
        </header>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: getUser(state),
        isAuthenticated: getIsAuthenticated(state),
    };
};

const mapDispatchToProps = {
    signout,
    getAccountDetails,
    resetOrders,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);
