import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FLOORBALL_COLOR_EMPTY_RED } from '../../../ressources/types/floorball.types';
import ColorPalette from '../ColorPalette/ColorPalette';
import './colorPickerModal.scss';

function ColorPickerModal(props: { action: any; active: string; enableAll?: boolean; initialColor?: string, isLeft: boolean, isRight: boolean}) {
    const { t } = useTranslation();
    const backgroundUrl = `url('/img/floorball/color-palette.png')`;
    const [open, setOpen] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState<string>('');
    const [background, setBackground] = useState<Object>({ backgroundImage: backgroundUrl });

    useEffect(() => {
        if (props.initialColor && props.initialColor !== FLOORBALL_COLOR_EMPTY_RED) {
            setSelectedColor(props.initialColor);
        }
    }, []);

    useEffect(() => {
        if (selectedColor) {
            setBackground({ backgroundColor: selectedColor });
        }
    }, [selectedColor]);

    function handleColorSelection(colorName: string, colorHex: string) {
        setSelectedColor(colorHex);
        setOpen(false);
        props.action(colorName, colorHex);
    }

    function handleCancel() {
        setSelectedColor('');
        setOpen(false);
        setBackground({ backgroundImage: backgroundUrl });
        props.action(FLOORBALL_COLOR_EMPTY_RED, null);
    }

    return (
        <div className='colorwrapper modal'>
            <Modal basic onClose={() => setOpen(false)} open={open} className='colorModal'>
                <Modal.Header>{t('colorPicker-mobileTitle')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ColorPalette action={handleColorSelection} active={props.active}
                                      enableAll={props.enableAll || false} isLeft={props.isLeft} isRight={props.isRight}/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={() => handleCancel()}>
                        {t('button-reset')}
                    </Button>
                    <Button color='red' onClick={() => setOpen(false)}>
                        {t('button-cancel')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <div className='floorball-color' style={background} onClick={() => setOpen(true)}></div>
        </div>
    );
}

export default ColorPickerModal;
