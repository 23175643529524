import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getActiveOrderError, getOrdersError } from '../../redux/reducers';
import './errorModal.scss';

function ErrorModal({ ordersError, orderError }: PropsFromRedux) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (ordersError || orderError) {
            setOpen(true);
        }
    }, [ordersError, orderError]);

    return (
        <Modal basic onClose={() => setOpen(false)} open={open} size='tiny' className='error-modal'>
            <Modal.Content>
                <Icon name='close' />
                <h2>An error has occurred!</h2>
                <p>Please try again...</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> {t("button-ok")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

const mapStateToProps = (state: any) => ({
    ordersError: getOrdersError(state),
    orderError: getActiveOrderError(state),
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps);

export default connector(ErrorModal);
