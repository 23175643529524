import React from 'react';
import { Table } from 'semantic-ui-react';
import { FloorballComponent } from '../../../ressources/types/floorball.types';
import { formatDateFromDateString } from '../../../utils/dateHelper';

function FloorballHalfData(props: { floorballHalf: FloorballComponent; side: 'left' | 'right' }) {
    return (
        <div className='component component2'>
            {props.floorballHalf.productionData && (
                <>
                    <div className='overview'>
                        <div className='image'>
                            <img
                                src={`/img/floorball/${props.side === 'left' ? 'l' : 'r'}_${props.floorballHalf.color.name}.png`}
                                alt='Floorball Half 1'
                            />
                        </div>
                        <div>
                            <h3>Serial Number</h3>
                            <p>{props.floorballHalf.materialNumber}</p>
                            <h3>Color</h3> <p>{props.floorballHalf.color.name}</p>
                        </div>
                    </div>
                    <div className='data'>
                        <div className='topic'>
                            <h3>Injection Molding</h3>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={2}>Date</Table.Cell>
                                        <Table.Cell>{formatDateFromDateString(props.floorballHalf.productionData._time)}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Injection Time</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.injectionTime}s</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Cycle Time</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.cycleTime}s</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Holding Pressure Time</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.holdingPressureTime}s</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Holding Pressure</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.holdingPressure} bar</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Holding Pressure Max</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.injectionPressure_Max} bar</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Melt Cushion</Table.Cell>
                                        <Table.Cell>
                                            {props.floorballHalf.productionData?.machineData.meltCushion} cm<sup>3</sup>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        <div className='topic'>
                            <h3>Temperatures</h3>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={2}>Cylinder Z1</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_Cylinder_Z1}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Cylinder Z2</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_Cylinder_Z2}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Flange</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_Flange}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Nozzle</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_Nozzle}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Tempering Device AS</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_TemperingDevice_AS}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Tempering Device DS</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.machineData.temperature_TemperingDevice_DS}°C</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        <div className='topic'>
                            <h3>Environment</h3>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={2}>Temperature</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.environmentData.temperature}°C</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={2}>Humidity</Table.Cell>
                                        <Table.Cell>{props.floorballHalf.productionData?.environmentData.humidity} %</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default FloorballHalfData;
