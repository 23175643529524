import React, { useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './confirmationModal.scss';
import { useTranslation } from 'react-i18next';

function ConfirmationModal(props: { open: boolean; setOpen: (state: boolean) => void; action: () => void; title: string; description: string }) {
    const {t} = useTranslation();

    useEffect(() => {
        props.setOpen(props.open);
    }, [props]);

    function action() {
        props.setOpen(false);
        props.action();
    }

    return (
        <Modal basic onClose={() => props.setOpen(false)} open={props.open} size='tiny' className='confirmation-modal'>
            <Modal.Content>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => props.setOpen(false)}>
                    <Icon name='remove' /> {t("button-no")}
                </Button>
                <Button color='green' onClick={() => action()}>
                    <Icon name='checkmark' /> {t("button-yes")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ConfirmationModal;
