import React, { ChangeEvent, useState } from 'react';
import {connect, ConnectedProps} from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../utils/validation';
import './account.scss';
import {getIsAuthenticated, getUser} from "../../redux/reducers";
import {changePassword} from "../../redux/actions";

function Account({user, isAuthenticated, changePassword} : PropsFromRedux) {
    const {t} = useTranslation();
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<boolean | undefined>(undefined);
    const [message, setMessage] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = (event: any) => {
        event.preventDefault();
        setMessage('');
        switch (false) {
            case currentPassword.length !== 0 && newPassword.length !== 0 && confirmPassword.length !== 0:
                setMessage(t("pages-account-errorMessages-required"));
                setError(true);
                break;
            case validatePassword(newPassword):
                setMessage(t("pages-account-errorMessages-password"));
                setError(true);
                break;
            case newPassword === confirmPassword:
                setMessage(t("pages-account-errorMessages-confirmPassword"));
                setError(true);
                break;
            default:
                setIsSubmitting(true);
                changePassword(currentPassword, newPassword, confirmPassword)
                    .then(() => {
                        setError(false)
                        setMessage(t("pages-account-success-changedPassword"))
                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                    })
                    .catch(() => {
                        setError(true)
                        setMessage(t("pages-account-errorMessages-serverError"))
                    })
                    .finally(() => setIsSubmitting(false))
        }
    };
    
    if (!isAuthenticated) {
        return <p>Not authenticated...</p>
    }
    return (
        <div id='account' className='account-container'>
            <h1>{t("pages-account-title")}</h1>
            <p>{t("placeholder-customer-number")}: {user.username} <br/>
                {t("placeholder-firstname")}/{t("placeholder-lastname")}: {user.firstname} {user.lastname}
            </p>
            <Form size='large' error={error}>
                <Form.Field>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCurrentPassword(event.target.value)}
                        placeholder={`${t("placeholder-currentPassword")} *`}
                        type='password'
                        value={currentPassword}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                        placeholder={`${t("placeholder-newPassword")} *`}
                        type='password'
                        value={newPassword}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                        placeholder={`${t("placeholder-confirmPassword")} *`}
                        type='password'
                        value={confirmPassword}
                    />
                </Form.Field>
                <Button
                        loading={isSubmitting} 
                        onClick={onSubmit} 
                        size='large' 
                        fluid={true} 
                        content={t("pages-account-changePassword")}
                        disabled={isSubmitting}/>
                {message !== '' && <Message error={error} content={message}/>}
            </Form>
            <p>
                <strong>
                    <span className='red'>*</span> {t("pages-account-required")}
                </strong>
            </p>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: getUser(state),
        isAuthenticated: getIsAuthenticated(state),
    };
};

const mapDispatchToProps = {
    changePassword
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Account);
